
function App() {
  return (
    <div className="App">
      <p>I am a test page.</p>
      <p className="text-white text-2xl font-bold">Test 123</p>
    </div>
  );
}

export default App;
